@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Regular-webfont.eot") format("eot"), local("Roboto Sans"), url("./assets/fonts/roboto/Roboto-Regular-webfont.woff") format("woff");
  font-weight: normal
}

@font-face {
  font-family: "RobotoLight";
  src: url("./assets/fonts/roboto/Roboto-Light-webfont.eot") format("eot"), local("Roboto Sans"), url("./assets/fonts/roboto/Roboto-Light-webfont.woff") format("woff");
  font-weight: normal
}

@font-face {
  font-family: "rbcdisplay-regular-webfont";
  src: url("./assets/fonts/display/webfontkit_RBCDisplay-Regular/rbcdisplay-regular-webfont.woff") format("woff")
}

@font-face {
  font-family: "rbcdisplay-light-webfont";
  src: url("./assets/fonts/display/webfontkit-RBCDisplay-Light/rbcdisplay-light-webfont.woff") format("woff")
}

@font-face {
  font-family: "firaSans";
  src: url("./assets/fonts/display/webfontkit-RBCDisplay-Light/rbcdisplay-light-webfont.woff") format("woff")
}


body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'rbcdisplay-regular-webfont', 'rbcdisplay-light-webfont', 'Oxygen', 'Ubuntu', 'RobotoLight', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}