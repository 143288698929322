.div-center {
    text-align: center;
    padding: 40px 0;
}

.noticesDiv {
    overflow-y: auto;
    height: 1062px;
    max-height: 100%;
    overflow-x: hidden;
    font-size: 14px !important;;
}

.underline {
    border-bottom: 4px solid #fedf01;
    width: 70px;
    height: 50px;
}

.title-color {
    color: #003168;
    justify-content: left;
    align-items: center;
    text-align: left;
    display: flex;
}

.date-font-size {
    font-size: 17px;
}
.Container {
    padding-left: 0px;
    padding-right: 0px;  
}
.keyContactsContainer {
    background-color: #0051a5;
    border-top: solid 5px #fedf01;
    margin: 30px 0 20px 0;
}

.keyContactsRow {
    margin-top: 30px;
    padding-right: 10px;
}

.chatIcon {
    height: 75px;
    margin-top: 20px;
    position: relative;
    padding-left: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.headerCol {
    font-weight: bold;
    color: black!important;
    font-size: 15px;
    background-color: white!important;
}

.white-bg {
    background-color: #f3f4f5!important;
}

.rowGrid {
    border: none!important;
}

.aura-ag-grid {
    border-top: none!important;
    margin-top: 10px;
}

.ag-header {
    border-bottom: 3px solid #333333!important;
}

.indexCol {
    word-wrap: break-word;
    text-decoration: none;
    color: #0F5494;
    font-size: 14px !important;;
    white-space: break-spaces;
    word-break: normal;
}

.AUGrid-root-6 {
    height: auto!important;
}

.au-grid {
    height: auto!important;
}

.btn-primary {
    color: #000000 !important;
    background: #fedf01;
    border: 1px solid #fedf01;
    border-radius: 2px;
    font-weight: bold;
    font-family: "Roboto";
    font-size: 16px;
    padding: 12.5px 40px;
    width: 100%;
}
#more:hover
{
    color: #000000 !important;
    background-color: #fec72c !important;
    border: 1px solid #fec72c !important;
    min-height: 100% !important;
    width: 100%;
    display: flex;
    text-decoration: none;
    height: 50px !important;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fedf01 !important;
    border: 1px solid #fedf01 !important;
}

.align-text-center
{
    text-align: center;
}

@media(max-width:999px) and (min-width:767px) {
    .noticesDiv {
        height: 1008.5px !important;
    }

}
@media(max-width:767px) and (min-width:576px)
{
    .col-sm-3{
        margin: 5px !important;
    }

    .keyContactSection{
        width: auto;
    }

    .noticesDiv {
        height: 986px !important;
    }
    
}

@media(max-width:1199px) and (min-width:1000px) {
    .noticesDiv {
        height: 1051px !important;
    }

}

@media(max-width:1399px) and (min-width:1200px) {
    .noticesDiv {
        height: 1077px !important;
    }

}

@media screen and (min-width: 2600px) { 
    .noticesDiv {
        height: 1072px !important;
    }
  }

@media(max-width:2599px) and (min-width:1400px) {
    .noticesDiv {
        height: 1063px !important;
    }

}

@media screen and (max-width: 767px) {
    #gridcnt,#wordingcnt{
        padding-left: 2px !important;
        padding-right: 2px !important;
        font-size: 14px;
    }
        
    .container{
        margin-left: 2px !important;
        margin-right: 2px !important;
    }
   
    .keyContactSection{
        width: auto;
    }

    .noticesDiv {
        height: 982px !important;
    }



  }