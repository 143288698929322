.health-check-container {
    display: flex;
    height: 40%;
    justify-content: center;
}

.health-check-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}