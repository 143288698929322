.div-title {
    background-color: #00429b;
    color: white;
    height: 100px;
    text-align: left;
    justify-content: center;
    align-content: center;
    font-family: "rbcdisplay-light-webfont";
    font-size: 26px;
    width: 100%;
    padding-top: 40px;

}
.div-center {
    text-align: center;
    padding: 40px 0;
}