.footer {
    background: #002046!important;
    margin-top: 50px;
    padding: 50px 0;
    position: relative;
}

/* line 11, ../../sass/widgets/_footer.scss */

.footer a {
    color: #fff;
    text-decoration: none;
}

.logo {
    width: 200px;
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
}

/* line 16, ../../sass/widgets/_footer.scss */

.copyright {
    margin: 15px 0 0;
}

.list-inline>li {
    display: inline-block;
    padding-right: 0px;
    padding-left: 15px;
}

/* line 20, ../../sass/widgets/_footer.scss */

.footer-social {
    font-weight: normal;
    color: #fff;
}

/* line 23, ../../sass/widgets/_footer.scss */

.footer-social i {
    padding: 10px;
    font-size: 20px;
}

.footer-menu-divider {
    color: #b5b5a9;
}

.h3 {
    font-size: 10px;
    font-weight: 600;
}

.disclaimer {
    color: #b5b5a9;
}

