.search {
    text-align: Left;
    padding: 10px 10px;
    width: 100%;
    border-bottom: 0px !important;
    border-bottom-color: #ffffff !important;
    border: none !important;
    background-color: #f3f7fb;
    color: black !important;
    height: 50px;
    font-size: 15px;
}

input:focus {
    outline: 0;
}

.searchImage {
    vertical-align: bottom;
    margin-top: 14px;
    height: 23px;
    width: 25px;
}

.ulSearch {
    background-color: #f3f7fb;
    margin: 0;
    list-style: none;
    display: flex;
    border-bottom: solid 1px;
    border-bottom-color: #dadada;
}