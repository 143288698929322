.index-info {
    padding: 10px 20px 40px 20px;
    background-color: #f3f7fb;
}

.nav-tabs .nav-link {
    color: black;
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent;
    font-size: 16px;
    padding-left: 0px!important;
    width: 150px;
    text-align: left;
    /* padding-right: 50px!important; */
}

.link-dark {
  padding-left: 0;
}

.roadmap {
  padding-left: 0;
}

.nav-tabs .nav-link.active {
    color: #006ac3;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: 5px solid #006ac3!important;
    font-size: 16px;
    padding-left: 0px!important;
    /* padding-right: 50px!important; */
    width: 150px;
    text-align: left;
}

.underline-title {
    height: 50px;
    font-family: "rbcdisplay-light-webfont";
    font-weight: bold;
    font-size: 24px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 20px;
}

.title-wrapper {
  word-break: break-word;
  line-height: 38px;
}

.underline-span {
    text-decoration: underline #fedf01;
    text-decoration-thickness:  2.5px;
    text-underline-offset: 0.32em;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-color: #fedf01;
}


/* .underline-title::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 15%;
    margin-top: 50%;
    bottom: 0;
    left: 0;
    background: #f24432;
    #fedf01
} */

.pipe-separated-list-container {
    overflow-x: hidden;
  }
  .pipe-separated-list-container .nav-pills {
    list-style-type: none;
    position: relative;
    left: -1px;
    padding: 0px;
  }
  .pipe-separated-list-container .nav-pills .nav-item {
    display: inline-block;
    line-height: 1;
    padding: 0 1em;
    margin-bottom: 1em;
    border-left: 1px solid;
  }

  tbody, td, tfoot, th, thead, tr {
     border-color: inherit;
     border-style: none;
     border-width: 0;
 }
table a {
    color: #0f5caa;
    text-decoration: none;
}

table
{
  font-size: 14px !important;
  font-weight: normal !important;
  border-style: none !important ;
}


  @media (max-width: 991px)
  {
    canvas{
      box-sizing: border-box !important; 
      display: block !important; 
      height: 100% !important; 
      width: 100% !important;
    }
    .ag-root-wrapper-body.ag-layout-auto-height.ag-focus-managed{
        width: 100% !important;
        left: 100% !important;
    }

  }

  .ag-root-wrapper-body.ag-layout-auto-height.ag-focus-managed{
    border-color: #d1d6dc;
    border-style: solid;
    border-width: 1px;
  }
  