.underline {
    border-bottom: 4px solid #fedf01;
    width: 70px;
    height: 50px;
}


.headerCol {
    font-weight: bold;
    color: black!important;
    font-size: 15px;
    background-color: white!important;
}

.white-bg {
    background-color: #f3f4f5!important;
}

.rowGrid {
    border: none!important;
}

.aura-ag-grid {
    border-top: none!important;
    margin-top: 10px
}

.ag-header {
    border-bottom: 3px solid #333333!important;
}

.roadmap {
    color: #0f5caa;
}

.indexCol {
    word-wrap: break-word;
    text-decoration: none;
    color: #0F5494;
    font-size: 14px !important;
    white-space: break-spaces;
    word-break: normal;
}

.AUGrid-root-6 {
    height: auto!important;
}

.ag-pivot-mode-panel {
    display: none !important;
}

.ag-unselectable.ag-column-drop.ag-column-drop-vertical.ag-column-drop-empty {
    display: none !important;
}

.ag-unselectable.g-column-drop.ag-column-drop-vertical.ag-column-drop-empty.ag-last-column-drop {
    display: none !important;
}

.ag-paging-row-summary-panel .myDiv{
    display: flex;
    margin: 8px;
}
.option {
    border: none !important;
}

.column {
    padding-top: 0px;
    float: left !important;
}

.ag-paging-panel.ag-unselectable {
    float: left !important;
}

.ag-side-button-label{
    color: black!important;
}

.ag-paging-panel {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.ag-column-select-column-label {
font-size: 14px !important;
}

.ag-side-button-button
{
font-weight: 600 !important;
}

.ag-theme-material .ag-paging-panel > * {
     margin: 0 0; 
     padding-right: 2%;
     padding-left: 2%;
}
select {
    word-wrap: normal;
    border: none;
}
.aura-ag-grid .ag-paging-panel, .ag-details-grid .ag-paging-panel {
   margin-bottom: 15px;
   margin-top: 15px;
   margin-left: 0.8px;
   margin-right: 0.8px;

}

.aura-ag-grid .ag-side-bar .ag-input-field-input
{
    height: 30px !important;
    font-size: 14px !important;
    border-bottom: 1px solid #ffffff !important;
}

.AUGrid-root-7 .aura-ag-grid .ag-side-bar {
    border-top: 0px;
}

@media screen and (max-width: 767px) {
    #gridcnt{
        padding-left: 2px !important;
        padding-right: 2px !important;
        font-size: 14px;
    }

  }