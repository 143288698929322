.navbar {
    background-color:white;
    height: 55px;
    display: flex;
    justify-content: end;
    align-items: right;
  }
  
  .menu-bars {
    margin-right: 0rem;
    font-size: 1.5rem;
    background: none;
    position: absolute;
    display: none;
    right: 13px;
    /* top: 10px; */
    color: #000;
    width: 42px;
    height: 32px;
    border-radius: 4px;
  }
  
 
  .nav-menu {
    background-color: white;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: left;
    position: absolute;
    /* top: 8px; */
    right: 0;
    padding: 0;
    transition: 850ms;
    color: black;
    z-index: 2;
    border-left: 3px solid rgba(0, 37, 75, 0.75);

  }
  
  .hide
  {
    display: none!important;
  }
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: end;
    align-items: center;
     /* padding: 8px 0px 8px 16px;   */
    list-style: none;
    height: 60px;
    color: black;
  }
  
  .nav-text a {
    text-decoration: none;
    color:black;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0px;
    border-radius: 2px;

  }
  
  .nav-text a:hover {
    background-color: #002144;
    color: white;
    padding: inherit;
  }
  
  .nav-menu-items {
    width: 100%;
  }
/*   
  .navbar-toggle {
    background-color: white;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: left;
  } */
  
  #closeNavbarIcon{

    display: block;
    font-size: 1.1rem;
    align-items: center;
    justify-items: center;
    margin-left: 15px;
    margin-top:10px;

    margin-right: 15px;
    
    margin-top: 8px;
    
    margin-bottom: 8px;
    
    background-color: transparent;
    
    background-image: none;
    
    border: 1px solid transparent;


  }

  #closeNavbarIcon:hover a:hover{
    background-color: #ddd !important;
    color: black;
  }

  .navbar-toggle {

    

    display: flex;

  
    padding-left: 28px;
  
  margin-right: 15px;
  

  
  margin-bottom: 8px;
  
  background-color: transparent;
  
  background-image: none;
  
  border: 1px solid transparent;
  
  border-radius: 4px;
  }


@media (max-width: 767px)
{

  .navbar-toggle {

  border: none;

  color: #000;
  }
}
