.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.form-control-dark {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: var(--bs-gray);
}

.form-control-dark:focus {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle {
  outline: 0;
}

.rbcImage {
  height: 50px;
  width: 109px;
  vertical-align: middle;
  float: left;
  align-items:flex-start;
  margin-top : 10px;
}

.mg-top {
  margin-top: 20px;
}

.mg-r {
  margin-right: 30px;
}

.bold-underline {
  font-weight: bold;
  border-bottom: 4px solid #fedf01!important;
  padding-bottom: 15px;
}

h1
{
  font-family: "rbcdisplay-light-webfont";
}
.navHeader
{
  justify-content: flex-end;
  width: 100%;
}
@media (min-width : 395px)
{
  .navHeader{
    padding-left: 50px ;
  }
}

@media only screen and (max-width:500px)
{

  Header {
    justify-content: left!important;
    
  }
  .navHeader.nav{
    flex-direction: column;
    height: auto;
  }
  .itemHeader:nth-child(1)
  {
    margin-top: 10px;

  }
  .itemHeader
  {
    width: 100%;
    text-align: center;
    margin-right: 0px;
  }
  .Hamburger,.bar
  {
    display: block !important;
  }

  .menu-bars:hover
  {
    background-color: #ddd !important;
  }
}

.Hamburger{
  display: none;
  position: absolute;
  right: 10px;
  top :7px;
  width: 22px;
  height: 32px;
}


.spanBar {
  display: block;
  width: 22px;
  z-index: 9001;  
  border-bottom: 2px solid #888;
  padding-bottom: 3.5px;
  align-items: center;
  justify-items: center;
  padding-left: 2px;;
}
.spanBarContainer
{
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 7px 10px;
  border-radius: 4px;
}
.spanBarContainer:hover{
  background-color: #ddd !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdownMenu svg{
  display: none;
}